<template>
    <div class="w-100">
        <h1 class="pb-3"> {{ $t('navigation.ready_for_invoicing') }} </h1>
        <div v-if="loading"> {{ $t('form.please_wait') }} </div>
        <div v-else-if="voyages.length == 0"> {{ $t('form.no_found', [$t('navigation.voyages', 2)]) }} </div>

        <template v-else v-for="voyage in voyages" :key="voyage.id">
            <h4 class="mt-4"> {{ voyage.name }} </h4>
            <template v-for="port in voyage.ports" :key="port.id">
                <h5 class="my-3"> {{ port.name + ', ' + port.country.name }} </h5>
                <template v-for="customer in port.customers" :key="customer.id">
                    <h6 class="fw-bold my-2"> {{ customer.relation ? customer.relation.name : '???' }} </h6>
                    <template v-for="(job, jindex) in customer.jobs" :key="job.id">

                        <div class="job-block" >
                            <template v-if="!job.is_invalid">
                                {{ setActive(voyage) }}
                                {{ setActive(job) }}

                                <template v-for="(booking, bindex) in job.bookings" :key="booking.id">
                                    <template v-for="(bl, blindex) in booking.bills_of_lading" :key="bl.id">
                                        <div class="row">
                                            {{ setActive(bl) }}
                                            <div class="col-auto" style="width: 40px">
                                                <FormItem :id="'unit-' + bl.id" v-model="bl.is_active" type="checkbox"
                                                    @onchange="checkActive(job)" />
                                            </div>
                                            <div class="col-3"> {{ job.number }} </div>
                                            <div class="col-3"> {{ booking.number }} </div>
                                            <div class="col-3"> {{ bl.number }} </div>
                                            <div class="col-1">
                                                {{ bl.job_units.length + ' ' + $tc('navigation.units', bl.job_units.length).toLowerCase() }}
                                            </div>
                                            <div class="col-auto" style="min-width: 150px" v-if="jindex == 0 && bindex == 0 && blindex == 0">
                                                <button class="btn btn-success position-absolute" type="button"
                                                    @click="editItem($event, voyage.id, customer)" :disabled="!job.is_active"
                                                    data-bs-toggle="modal" data-bs-target="#formModal">
                                                    <span class="fa fa-plus"></span> {{ $t('accounting.create_invoice') }}
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <div class="row invalidjob">
                                    <div class="col-auto" style="width: 40px"></div>
                                    <div class="col-3"> {{ job.number }} </div>
                                    <div class="col-7">{{$t(job.invalid_reason)}}</div>
                                    <div class="col-auto" style="min-width: 150px"></div>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
            </template>
        </template>
    </div>

    <FormPopup v-on:save="save" v-on:del="del" :itemId="null" :itemName="item ? item.name : null"
        :moduleName="$t('navigation.invoices')" :loading="loading" modalSize="modal-xl" modalHeight="90%"
        modalWidth="1400px" :isCopy="false" :itemStatus="null" ref="FormRef">
        <slot name='form'>
            <ReadyInvoiceForm ref="ItemForm" :itemData="item" />
        </slot>
        <template #modal-footer>
            <button id="pdfbutton" type="button" class="btn btn-primary" @click.prevent="itemFormRef.showPDF()">
                <i class="fa fa-file-pdf"></i>&ensp;{{$t('accounting.show_pdf')}}
            </button>
        </template>
    </FormPopup>

</template>

<script>
    import voyageService from '@/services/VoyageService';
    import invoiceService from '@/services/InvoiceService';
    import FormPopup from '@/components/FormPopup.vue';
    import ReadyInvoiceForm from '@/components/invoice/InvoiceFormNew.vue';

    export default {
        components: {
            FormPopup,
            ReadyInvoiceForm
        },
        data() {
            return {
                voyages: [],
                invalidJobs: [],
                item: null,
                itemLoading: false,
                itemFormRef: {},
                loading: true
            }
        },
        methods: {
            getIndex() {
                voyageService.getInvoiceReadyVoyages().then(response => {
                    this.voyages = response.data;
                    this.loading = false;
                    console.log(response.data);
                }).catch(error => {
                    console.log('error', error)
                });
            },
            editItem(e, voyageId, customer) {
                this.itemLoading = true;
                let blIds = [];
                customer.jobs.forEach(job => {
                    job.bookings.forEach(booking => {
                        booking.bills_of_lading.forEach(bl => {
                            if (bl.is_active) {
                                blIds.push(bl.id);
                            }
                        })
                    });
                });
                const blIdString = blIds.join('-');
                voyageService.getInvoiceReadyVoyage(voyageId, customer.id, blIdString).then(response => {
                    this.itemLoading = false;
                    this.item = response.data
                    this.itemFormRef.setData(response.data);
                    window.location.hash = voyageId;
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                })
            },
            setActive(item) {
                if (item.is_active === undefined)
                    item.is_active = true;
            },
            clearInvalidJobs() {
                this.invalidJobs = []
            },
            setInvalidJob(item) {
                if(!item.isInvalid){
                    this.invalidJobs.push(item)
                    item.isInvalid = true;
                }
            },
            checkActive(job) {
                let active = false;
                job.bookings.forEach(booking => {
                    booking.bills_of_lading.forEach(bl => {
                        if (bl.is_active) {
                            active = true;
                            return;
                        }
                    });
                    if (active) return;
                });
                job.is_active = active;
            },
            save() {
                let validate = this.itemFormRef.validate();
                if (!validate) return false;
                let item = this.itemFormRef.getData();

                invoiceService.store(item).then(() => {
                    window.location.hash = '';
                    this.getIndex();
                    document.querySelector("#modal-close-button").click();
                }).catch(error => {
                    this.toastError(error)
                });
            },
            del() {
                console.log('');
            }
        },
        mounted() {
            this.getIndex();
            this.itemFormRef = this.$refs.ItemForm;
        }
    }
</script>
<style scoped>
.job-block .invalidjob:first-child:before{
    content: "Pending";
    font-weight: 700!important;
    width: 99%;
    margin-left: 50px;
}
.job-block .invalidjob:last-child{
    margin-bottom: 15px;
}
</style>